// PartNumberForm.js
import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const PartNumberForm = () => {
    const [partNumbers, setPartNumbers] = useState('');
    const [alertMessage, setAlertMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setAlertMessage(''); // Clear previous messages

        const partNumbersArray = partNumbers.split(',').map(partNumber => partNumber.trim());

        try {
            const response = await axios.post('https://mabket-part-number.onrender.com/part-number', { partNumbers: partNumbersArray });
            console.log('Backend response:', response.data);
            setAlertMessage('Part numbers saved successfully!');
        } catch (error) {
            console.error('Error saving part numbers:', error.response || error.message || error);
            setAlertMessage(`Failed to save part numbers. Please try again. Error: ${error.response ? error.response.data.error : error.message}`);
        }
    };

    return (
        <div className="part-number-form">
            <h2>Enter Part Numbers</h2>
            <form onSubmit={handleSubmit}>
                <textarea
                    rows="5"
                    cols="50"
                    placeholder="Enter part numbers separated by commas"
                    value={partNumbers}
                    onChange={(e) => setPartNumbers(e.target.value)}
                ></textarea>
                <br />
                <button type="submit">Submit</button>
            </form>
            {alertMessage && <p>{alertMessage}</p>}
            <Link to="/">Go Home</Link>
        </div>
    );
};

export default PartNumberForm;
