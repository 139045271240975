// PartNumberPage.js
import React from 'react';
import PartNumberForm from './PartNumberForm';

const PartNumberPage = () => {
    return (
        <div className="part-number-page">
            <h2>Part Number Entry</h2>
            <PartNumberForm />
        </div>
    );
};

export default PartNumberPage;
