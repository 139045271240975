import React from 'react';
import Main from './upload/Main';
import PartNumberPage from './PartNumberPage';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

export default function App() {
    return (
        <div className="app">
            <Router>
                <div className="App">
                    <main>
                        <Routes>
                            <Route path="/" element={<Main />} />
                            <Route path="/part-number" element={<PartNumberPage />} />
                            {/* Add other routes as needed */}
                        </Routes>
                    </main>
                </div>
            </Router>
        </div>
    );
}
